<template>
  <div class="left-panel no-scrollbar">
    <div
      v-if="packetDetailsLoading"
      class="loading-animated"
    ></div>
    <div
      v-else
      v-for="(tab, index) in tabsList" 
      v-bind="tab"
      v-bind:key="tab + index"
      v-bind:class="{
        active: index === tabIsOpened, 
        collapsible: tab.children
      }"
      v-bind:index="index">
      <div
        class="selector"
        v-on:click="selectData($event, index, tab.name)"
      ><span class="arrow-icon"></span>
        &nbsp;<span class="text">{{tab.name}}</span>
      </div>
      <div v-if="tab.children" class="collapsible-lower">
        <div
          v-for="(subtab, index) in tab.children" 
          v-bind="subtab"
          v-bind:key="subtab + index"
          v-bind:class="{
            active: index === subTabIsOpened, 
            collapsible: subtab.children
          }"
          v-bind:index="index"
        >
          <div
            class="selector"
            v-on:click="selectData($event, index, subtab.name)"
          ><span v-if="subtab.children" class="arrow-icon"></span>
            &nbsp;<span class="text">{{subtab.name}}</span></div>
          <div
            v-if="subtab.children"
            class="collapsible-lower"
          >
            <div
              v-for="(subsubtab, index) in subtab.children" 
              v-bind="subsubtab"
              v-bind:key="subsubtab + index"
              v-bind:class="{
                active: index === subSubTabIsOpened, 
                collapsible: subsubtab.children
              }"
              v-bind:index="index"
            >
              <div
                class="selector"
                v-on:click="selectData($event, index, subsubtab.name)"
              ><span v-if="subsubtab.children" class="arrow-icon"></span>
                &nbsp;<span class="text">{{subsubtab.name}}</span></div>
              <div
                v-if="subsubtab.children"
                class="collapsible-lower"
              >
                <div
                  v-for="(subsubsubtab, index) in subsubtab.children" 
                  v-bind="subsubsubtab"
                  v-bind:key="subsubsubtab + index"
                  v-bind:class="{
                    active: index === subSubSubTabIsOpened, 
                    collapsible: subsubsubtab.children
                  }"
                  v-bind:index="index"
                >
                  <div 
                    class="selector"
                    v-on:click="selectData($event, index, subsubsubtab.name)"  
                  >
                    <span class="text">{{subsubsubtab.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'LeftPane',
  props: {
    isLadder: Boolean
  },
  data() {
      return {
        tabsList: [],
        selectedStat: 0,
        tabIsOpened: -1,
        subTabIsOpened: -1,
        subSubTabIsOpened: -1,
        subSubSubTabIsOpened: -1,
      }
  },
  computed: {
    ...mapState({
      packetDetails: state => state.packets.packetDetails,
      selectedPacket: state => state.packets.selectedPacket,
      uuid: state => state.packets.uuid,
      packetDetailsLoading: state => state.packets.packetDetailsLoading
    }),
    // packetDetailsLength () {
    //   return this.packetDetails && this.packetDetails.length;
    // }
  },
  watch: {
    selectedPacket(newValue) {

      if (newValue) {
        this.getDetails(newValue);
        console.log(newValue, 'selected');
      }
    },
    packetDetails(newValue) {
      if (newValue) {
        this.tabsList = this.packetDetails;
      } else {
        this.tabsList = [];
      }
    },
  },
  methods: {
    ...mapActions({
        getDetails: 'packets/getPacketDetails',
    }),
    openTab () {},
    selectData (ev, ind, name) {
      console.log('choose data peace by ', ind, name)
      let selectors = document.getElementsByClassName("selector");

      [...selectors].forEach(sel => {
        let unactive = sel.className.split(" ");

        sel.className = `${unactive[0]} ${unactive[1] === 'opened' ? 'opened' : ''}`;
      });

      let splitted = ev.currentTarget.className.split(" ");
      ev.currentTarget.className = `${splitted[0]} ${
        splitted[1] === 'opened' ? '' : 'opened'
      } ${splitted[2] ? '' : 'active'}`;

      let splittedSibling = ev.currentTarget.nextElementSibling.className.split(" ");
      ev.currentTarget.nextElementSibling.className = `${splittedSibling[0]} ${
        splittedSibling[1] === 'active' ? '' : 'active'
      }`;
    },
  },
  mounted() {
    if (this.packetDetails.length > 0) {
      console.log(this.packetDetails);
      this.tabsList = this.packetDetails;
    } else if (this.uuid) {
      this.getDetails(this.selectedPacket);
    }
  }
}
</script>

<style>
/* .left-panel {
    position: absolute;
    padding-left: 4px;
    height: 100%;
    right: 0;
    background-color: #fff;
} */
.left-panel {
  padding-top: 10px;
  padding-left: 15px;
  max-height: 390px;
  overflow: scroll;
  position: relative;
}

.collapsible {
  cursor: pointer;
  width: auto;
  border: none;
  text-align: left;
  outline: none;
  display: flex;
  align-items: center;
  white-space: initial;

  overflow: hidden;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.collapsible .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.collapsible-lower {
  display: none;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

.collapsible-lower .selector {
  margin-left: 20px;
}

.collapsible.active .collapsible-lower {
  display: flex;
}

.collapsible .collapsible-lower.active {
  display: flex;
}

.collapsible .arrow-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../assets/arrow_icon.svg');
  mask-image: url('../../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
  margin: 0 7px 0 13px;
}

.loading-animated {
  background: url('../../assets/loading.gif');
  width: 70px;
  height: 70px;
  background-size: contain;
  margin: auto;
}

.selector.opened .arrow-icon {
  transform: rotate(90deg);
}

.selector.active {
  background: #a7cbfd;
}

.selector.active .text,
.selector.active .arrow-icon {
  z-index: 1;
}

.selector.active::before {
  content: " ";
  width: 100%;
  position: absolute;
  height: 16px;
  background: #a7cbfd;
  left: 0;
}
.panes.mobile .left-panel .selector.active::before {
  height: 32px;
}

</style>
