import axios from 'axios'

// axios
    //   .get(
    //       'http://192.99.10.113:8001/pcap/42211ec9-087a-4cc5-956e-4d2583f91309/packet_list',
    //       { headers: { 
    //         // 'Accept': 'application/json; charset=utf-8',
    //         'Access-Control-Allow-Origin' : '*', 
    //         // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT,DELETE',
    //         'Content-Type': 'application/json; charset=utf-8' } }
    //     )
    //   .then(response => (this.datas = response.data))
    //   .catch(error => console.log(error))


// initial state

const state = () => ({
  uploadedFile: {
    fileSize: '',
    numberOfPackets: '',
    fileName: '',
  },
  uuid: '',
  frame: '',
  packetDetails: [],
  packetBytes: '',
  packetList: [],
  fullPacketList: [],
  dialogContent : {},
  dialogInternal: {},
  selectedPacket: 0,
  localFile: {},
  dialogLoading: '',
  listLoading: '',
  packetDetailsLoading: '',
  bytesLoading: '',
  errorResponse: {}
})
// const apiUrl = '';
const apiUrl = 'https://api.sipshark.denovolab.com/pcap';
const localUrls = {
  upload: `${apiUrl}/data/upload.json`,
  packetList:  () => `${apiUrl}/data/packet_list.json`,
  fileInfo: () => `${apiUrl}/data/file_info.json`,
  packetDetails:  () => `${apiUrl}/data/packet_details.json`,
  packetBytes: () => `${apiUrl}/data/packet_bytes.json`,
  ProtocolHierarchyStats:  () => `${apiUrl}/data/get_protocol_hierarcy.json`,
  NetworkEndpoints:  () => `${apiUrl}/data/get_ladder_view-endpoints.json`,
  ProtocolConversations:  () => `${apiUrl}/data/get_ladder_view-protocols.json`,
  RTPStreams:  () => `${apiUrl}/data/get_rtp_streams.json`,

  VoIPCalls:  () => `${apiUrl}/data/get_voip_calls.json`,
  FollowUDPStream: () => `${apiUrl}/data/see_sip_stream.json`,
  dialogInternal: {
    FollowUDPStream: () => `${apiUrl}/data/get_hex_dump.json`,
  },
  GeoIPWorldMap:  () => `${apiUrl}/data/`,
  PacketLengths:  () => `${apiUrl}/data/`,
  DNSActivity:  () => `${apiUrl}/data/`,
  HTTPAnalysis:  () => `${apiUrl}/data/`,
  WirelessNetworks:  () => `${apiUrl}/data/`,
  ThreatAssesment:  () => `${apiUrl}/data/`,
  ZeekLogs:  () => `${apiUrl}/data/`,
  SIPStats: () => `${apiUrl}/data/get_sip_stat`,
};

const apiUrls = {
  upload: `${apiUrl}/upload`,
  existingFiles: `${apiUrl}`,
  packetList: (uuid) => `${apiUrl}/${uuid}/packet_list`,
  fileInfo: (uuid) => `${apiUrl}/${uuid}/info`,
  packetDetails: (uuid, selected) => `${apiUrl}/${uuid}/${selected}/packet_details`,
  packetBytes: (uuid, selected) => `${apiUrl}/${uuid}/${selected}/packet_bytes`,

  ProtocolHierarchyStats: (uuid) => `${apiUrl}/${uuid}/get_protocol_hierarcy`,
  NetworkEndpoints: (uuid) => `${apiUrl}/${uuid}/get_ladder_view/endpoints`,
  ProtocolConversations:  (uuid) => `${apiUrl}/${uuid}/get_ladder_view/protocols`,
  RTPStreams: (uuid) => `${apiUrl}/${uuid}/get_rtp_streams`,
  FollowUDPStream: (uuid) => `${apiUrl}/${uuid}/see_sip_conversation`,
  dialogInternal: {
    FollowUDPStream: (uuid) => `${apiUrl}/${uuid}/get_hex_dump`,
    RTPStreams: (uuid) => `${apiUrl}/${uuid}/get_audio_links`,
    AudioFlow: (uuid) => `${apiUrl}/${uuid}/get_audio_links`,
    SIPStats: (uuid) => `${apiUrl}/${uuid}/get_sip_stat`,
  },
  VoIPCalls:  (uuid) => `${apiUrl}/${uuid}/get_voip_calls`,
  GeoIPWorldMap:  (uuid) => `${apiUrl}/${uuid}/info`, // REPLACE TO REAL ONE
  PacketLengths:  (uuid) => `${apiUrl}/${uuid}/get_packet_size_stat`,
  DNSActivity:  (uuid) => `${apiUrl}/${uuid}/`,
  HTTPAnalysis:  (uuid) => `${apiUrl}/${uuid}/`,
  WirelessNetworks:  (uuid) => `${apiUrl}/${uuid}/`,
  ThreatAssesment:  (uuid) => `${apiUrl}/${uuid}/`,
  ZeekLogs:  (uuid) => `${apiUrl}/${uuid}/`,
  LeftPaneDialog: (uuid) => `${apiUrl}/${uuid}/packet_list`,

  GraphsStats: (uuid) => `${apiUrl}/${uuid}/rtp_data`,
  SIPStats: (uuid) => `${apiUrl}/${uuid}/get_sip_stat`,

  // (uuid) => `${uuid}/get_audio_file/${file}`
  // (uuid) => `${uuid}/info`
  // (uuid) => `${uuid}/get_sip_stat`
}
console.log(apiUrls, localUrls);
const urls = apiUrls;
// const urls = localUrls;

// getters
const getters = {
  uploadedFileFormat: (state) => {
    let uploaded = state.uploadedFile;

    uploaded && Reflect.deleteProperty(uploaded, 'captureComment');

    return {
      ...uploaded,
      fileSize: (uploaded && uploaded.fileSize) ? 
        `${ Math.abs(parseInt(uploaded.fileSize) / 1000).toFixed(2)} kb` : '',

      numberOfPackets: (uploaded && uploaded.numberOfPackets) ? 
        `${uploaded.numberOfPackets} packets` : '',

      fileName: (uploaded && uploaded.fileName) ? 
        `${
          uploaded.fileName
            .substring(uploaded.fileName.lastIndexOf("/") + 1, 
            uploaded.length)
          }` : '',
    }
  },
  getSelectedPacketNum: (state) => state.selectedPacket,
  packetsFrequency: (state) => {

    let list = state.packetList;

    const filteredPackets = (list && list.length > 0) && list.map(fr => {
      return {
        time: `${fr.time.split('.')[0]}.${fr.time.split('.')[1].slice(-3)}`, 
        counter: fr.length//list.filter(frq => frq.length === fr.length).length
      }
    })//.filter((v,i,a)=>a.findIndex(t=>(t.length === v.length))===i);

    return filteredPackets;
  },
  fullPacketListFrequency: (state) => {

    let list = state.fullPacketList;

    const filteredPackets = (list && list.length > 0) && list.map(fr => {
      return {
        time: `${fr.time.split('.')[0]}.${fr.time.split('.')[1].slice(-3)}`, 
        counter: fr.length//list.filter(frq => frq.length === fr.length).length
      }
    })//.filter((v,i,a)=>a.findIndex(t=>(t.length === v.length))===i);

    return filteredPackets;
  },
  // packetsByLengths: (state) => {

  //   let list = state.packetList;

  //   const defaultSet = [
  //     ["0-19"],
  //     ["20-39"],
  //     ["40-79"],
  //     ["80-159"],
  //     ["160-319"],
  //     ["320-639"],
  //     ["640-1279"],
  //     ["1280-2559"],
  //     ["2560-5119"],
  //     ["5120 and greater"],
  //   ]; // split("-");

  //   let counter = 0,
  //     lengthsSum = 0,
  //     lengthsList = [];

  //   list.forEach(packet => {
  //     // compare each packet against splitted[0] and splitted[1] forEach in defaultSet
  //     defaultSet.forEach((pair, indx) => {
  //       let splitted = pair[indx].split("-");

  //       if (packet.length >= splitted[0] && packet.length <= splitted[1]) {
  //         counter++;
  //         lengthsSum += packet.length;
  //         lengthsList = [...lengthsList, packet.length];
  //       }
  //     });
  //   });
  //   const min = Math.min(...lengthsList);
  //   const max = Math.max(...lengthsList);
  //   const average = lengthsSum / counter;
    
  //   defaultSet.forEach(pair => {
  //     pair.push(min, max, average)
  //   });

  //   return null;
  // },
  internalDialogFormat: (state) => {

    let intCont = state.dialogInternal && Object.entries(state.dialogInternal),
        parts = (intCont && intCont[0]) && intCont[0][1];

        let everyRow = parts && parts.match(/.{1,72}/g);

        return everyRow && everyRow.map(er => 
          `${er.slice(0, 5)} 
          ${String.fromCharCode(160)}
          ${er.slice(5, 48)}
          ${String.fromCharCode(160)}
          ${er.slice(48)}`);
  }
}

// actions
const actions = {

  uploadAndGetUUID({commit}, file) {
    let formData = new FormData();
        formData.append('file', file);
    console.log(file)
    commit('saveFileLocally', file);
    commit('setUUID', {})
    axios.post(urls.upload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    // axios.get(urls.upload)
      .then(res => commit('setUUID', {uuid: res.data.id})
    ).catch((e) => {
      let error = e;
      commit('setUUID', {uuid: ''});
      commit('setError', {status: true, statusCode: error && error.statusCode});
    });
  },

  getExistingFile({commit}, uuid) {
    commit('setUUID', {})
    axios.get(`${urls.existingFiles}`)
      .then(res => {
        let requiredFile = res.data.find(exfile => exfile.id === uuid);
        commit('setUUID', {uuid: requiredFile.id});
      }
    ).catch((e) => {
      if (e) {
        commit('setUUID', {uuid: '', });
        commit('setError', {status: true, statusCode: 'file with this ID is not found'})
      }
    });
  },

  emptifyError({commit}) {
    commit('setError', {});
  }, 

  getFileInfo({commit, state}) {
    commit('setUploadedFile', {})
    state.uuid && axios.get(`${urls.fileInfo(state.uuid)}`)
      .then(res => commit('setUploadedFile', {fileInfo: res.data})
    ).catch(() => 
      commit('setUploadedFile', {fileInfo: {}}));
  },

  getPacketsList({commit, state}, {filter, timeDisplay, calls}) {
    const savedContent = state.packetList;

    commit('setListLoading', 'loading');
    
    // commit('setPacketsList', [])
    state.uuid && 
      axios.get(`${urls.packetList(state.uuid)}${
        `?filter=${filter ? filter : ''}`
      }${
        timeDisplay ? `&time_display=${timeDisplay}` : ''
      }${
        (calls || (calls === 0)) ? `&calls=${calls}` : ''
      }`)
        .then(
          res => {
            commit('setPacketsList', {packetList: res.data});
            commit('setListLoading', '');
          }
        ).catch(() => {
          commit('setPacketsList', {packetList: savedContent});
          commit('setListLoading', '');
        });

  },

  getPacketDetails({commit, state}, selected) {

    console.log(selected);

    const savedContent = state.packetDetails;

    commit('setPacketsDetails', []);
    commit('setPacketDetailsLoading', 'loading');

    state.uuid && axios.get(`${urls.packetDetails(state.uuid, selected)}`)
      .then(res => {
        commit('setPacketsDetails', {packetDetails: res.data});
        commit('setPacketDetailsLoading', '');
      }
    ).catch(() => 
      {
        commit('setPacketsDetails', {packetDetails: savedContent});
        commit('setPacketDetailsLoading', '');
      });

  },

  getPacketBytes({commit, state}, selected) {
    
    console.log(selected, 'bytes');

    const savedContent = state.packetBytes;

    commit('setPacketBytes', '');
    commit('setBytesLoading', 'loading');
    
    state.uuid && axios.get(`${urls.packetBytes(state.uuid, selected)}`)
      .then(res => {
        commit('setPacketBytes', {packetBytes: res.data});
        commit('setBytesLoading', '');
      }
    ).catch(() => 
      {
        commit('setPacketBytes', {packetBytes: savedContent});
        commit('setBytesLoading', '');
      });

  },

  getSelectedPacket({commit}, selected) {
    console.log(selected);
    selected && commit('setSelectedPacket', selected);
  },

  loadDialogData ({ commit, state }, dialog) {

    const savedContent = {...state.dialogContent};

    commit('setDialogContent', {});
    commit('setDialogLoading', 'loading');

    dialog && axios.get(`${urls[dialog.value](state.uuid)}${dialog.query ? `?${dialog.query}` : ''}`)
      .then(res =>
      {
        commit('setDialogContent', {content: {[dialog.value]: res.data, isLadder: dialog.isLadder}});
        commit('setDialogLoading', '');
      }
    ).catch(() => 
      {
        commit('setDialogContent', {content: savedContent});
        commit('setDialogLoading', '');
      }
    );

    dialog && commit('views/setCurrentDialog', dialog, { root: true });
  },

  dialogInternalRequest ({commit, state}, dialog) {

    const savedContent = state.dialogInternal;

    axios.get(urls.dialogInternal[dialog](state.uuid)).then(res =>
      commit('setDialogInternal', {dialogInternal: res.data.data ? res.data.data : res.data})
    ).catch(() => 
      commit('setDialogInternal', {dialogInternal: savedContent}));

  },

  removeUploadedPacket({commit, state}) {
    commit('setUUID', {})
    commit('setUploadedFile', {})
    commit('setPacketsList', [])
    commit('setPacketsDetails', [])
    commit('setPacketBytes', '');
    commit('setSelectedPacket', 0);
    commit('setDialogContent', {});
    commit('setDialogInternal', {});
    commit('saveFileLocally', {});
    commit('setDialogLoading', '');
    commit('setListLoading', '');
    commit('setPacketDetailsLoading', '');
    commit('setBytesLoading', '');
    commit('views/setCurrentDialog', {}, {root: true});
    commit('views/setCurrentWindow', {}, {root: true});
    commit('views/setDiagramOpened', {}, {root: true});
    console.log(state);
  }
}

// mutations
const mutations = {
  setDialogContent (state, { content }) {
    console.log(state.dialogContent, content);
    state.dialogContent = content ? {...content, isLadder: content.isLadder} : {};
    console.log(state.dialogContent);
  },
  setDialogLoading(state, status) {
    state.dialogLoading = status;
  },
  setPacketsList (state, {packetList}) {
    state.packetList = packetList;
    state.selectedPacket = 1;
    state.fullPacketList = (state.fullPacketList.length <= 0) ? 
      packetList : state.fullPacketList;
  },
  setListLoading(state, status) {
    state.listLoading = status;
  },
  setUUID(state, {uuid}) {
    state.uuid = uuid;
  },
  setUploadedFile (state, {fileInfo}) {
    state.uploadedFile = fileInfo;
  },
  setPacketsDetails(state, {packetDetails}) {
    state.packetDetails = packetDetails;
  },
  setPacketDetailsLoading(state, status) {
    state.packetDetailsLoading = status;
  },
  setSelectedPacket(state, selected) {
    state.selectedPacket = selected;
  },
  setPacketBytes(state, {packetBytes}) {
    state.packetBytes = packetBytes && packetBytes.data;
  },
  setBytesLoading(state, status) {
    state.bytesLoading = status;
  },
  setDialogInternal(state, dialogInternal) {
    state.dialogInternal = dialogInternal;
  },
  saveFileLocally(state, file) {
    state.localFile = file;
  },
  setError(state, error) {
    state.errorResponse = error;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}